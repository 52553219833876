class AppUrl {
	static BaseURL = 'https://elevatorauto.ro/api';
	static StaticURL = 'https://elevatorauto.ro';
	
	static Settings = this.BaseURL+'/setting';
	static Block = this.BaseURL+'/block';
	static Cta = this.BaseURL+'/cta';
	static Title = this.BaseURL+'/title';
	static Product = this.BaseURL+'/product';
}

export default AppUrl