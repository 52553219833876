import React, { useState, useEffect, Fragment } from 'react'
import Modal from 'react-bootstrap/Modal';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

function Termeni() {
  const [show, setShow] = useState(false);
  const [blockData, setBlockData] = useState({
    block_title: "",
    block_subtitle: "",
    block_content: "",
    block_image: "",
    block_button: "",
    block_url: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    RestClient.GetRequest(AppUrl.Block).then(result => {
      const block = result.find(block => block.id === 17);
      if (block) {
        setBlockData({
          block_title: block.block_title,
          block_subtitle: block.block_subtitle,
          block_content: block.block_content,
          block_image: block.block_image,
          block_button: block.block_button,
          block_url: block.block_url,
        });
      }
    });
  }, []);

  return (
    <Fragment>
        <li onClick={handleShow}>
            Termeni si conditii
        </li>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            size="lg"
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title>Termeni si conditii</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: blockData.block_content }} />
            </Modal.Body>
        </Modal>
    </Fragment>
  );
}

export default Termeni;
