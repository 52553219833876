import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Animated} from "react-animated-css";

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

class Hero extends Component {

    constructor(){
        super();
        this.state={
        block_title:"",
        block_subtitle:"",
        block_content:"",
        block_image:"",
        block_button:"",
        block_url:"",
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Block).then(result=>{
            const blockData = result.find(block => block.id === 18);
            if(blockData) {
                this.setState({
                    block_title: blockData.block_title,
                    block_subtitle: blockData.block_subtitle,
                    block_content: blockData.block_content,
                    block_image: blockData.block_image,
                    block_button: blockData.block_button,
                    block_url: blockData.block_url,
                });
            }
        }) 
    }
    

	render() {
		return (
			<Fragment>
            <section id="hero">
                <Container>
                    <Row>
                        <Col xs={12} md={6} lg={6}>
                        <div className='hero_content'>
                        <Animated animationIn="bounceInLeft" animationOut="fadeOut" animationInDelay="1200" animationInDuration="1200" isVisible={true}>
                            <p><span>{this.state.block_subtitle}</span></p>
                            <h1>{this.state.block_title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: this.state.block_content }} />
                            {/* Verifică dacă block_button și block_url există înainte de a afișa butonul */}
                            {this.state.block_button && this.state.block_url &&
                                <a href={this.state.block_url} target="_blank" rel="noopener noreferrer">
                                    <Button variant="outline-danger" size="lg">
                                        {this.state.block_button}
                                    </Button>
                                </a>
                            }
                            </Animated>
                        </div>
                    </Col>
                        <Col xs={12} md={6} lg={6}>
                            <Animated animationIn="bounceInRight" animationOut="fadeOut" animationInDelay="800" animationInDuration="1200" isVisible={true}>
                                <picture>
                                    <source srcSet={`${AppUrl.StaticURL}/${this.state.block_image}`} alt={this.state.block_title} title={this.state.block_title} width="675" height="765" type="image/webp" />
                                    <img src={`${AppUrl.StaticURL}/${this.state.block_image}`} alt={this.state.block_title} title={this.state.block_title} width="675" height="765" />
                                </picture>
                            </Animated>
                        </Col>
                    </Row>
                </Container>
            </section>
			</Fragment>
		)
	}

}

export default Hero