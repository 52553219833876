import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

class Title extends Component {

    constructor(){
        super();
        this.state={
            title_heading:"",
            title_subtitle:"",
            title_content:"",
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Title).then(result=>{
            const titleData = result.find(title => title.id === 16);
            if(titleData) {
                this.setState({
                    title_heading: titleData.title_heading,
                    title_subtitle: titleData.title_subtitle,
                    title_content: titleData.title_content,
                });
            }
        }) 
    }
    

	render() {
		return (
			<Fragment>
            <hr className='divider'/>
            <section className="title">
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <p><span>{this.state.title_subtitle}</span></p>
                            <h2>{this.state.title_heading}</h2>
                        </Col>
                    </Row>
                </Container>
            </section>
			</Fragment>
		)
	}

}

export default Title