import React, { Component, Fragment } from 'react'

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export class Product extends Component {
  constructor(){
      super();
      this.state={
        productData:[] 
      }
  }

  componentDidMount(){
    RestClient.GetRequest(AppUrl.Product).then(result=>{
      // Filtrare în funcție de black_friday și sortare în funcție de product_id
      const filteredData = result
        .filter(product => Number(product.black_friday) === 1)
        .sort((a, b) => a.product_id - b.product_id);
      this.setState({ productData: filteredData }); 
    }) 
  }

  render() {
    const ProductView = this.state.productData.map((product, index) => {
      return (
        <Col xs={6} md={4} lg={3} className='product_block' key={index}>
          <a href={`${AppUrl.StaticURL}/produs/${product.product_slug}`}>
            <picture>
                <source srcSet={`${AppUrl.StaticURL}/${product.product_thumbnail}`} type="image/webp" />
                <img src={`${AppUrl.StaticURL}/${product.product_thumbnail}`} alt={product.product_image_alt} title={product.product_image_title} width="350" height="350" />
            </picture>
          </a>
          <div className='product_content'>
            <h3>{product.product_name}</h3>
            <p><span>Model: {product.product_model}</span></p>
            {product.offer_price && product.offer_price > 0 && (
              <p id="discount">
                {`-${Math.round(((product.product_price - product.offer_price) / product.product_price) * 100)}%`}
              </p>
            )}

            <p>
              <span id="price">
                {product.offer_price && product.offer_price > 0 
                    ? Math.round(product.offer_price * 1.19) 
                    : Math.round(product.product_price * 1.19)} Lei
              </span>
            </p>
            <p>
              <span id="old_price">
                {Math.round(product.product_price * 1.19)} Lei
              </span>
            </p>

            <a href={`${AppUrl.StaticURL}/produs/${product.product_slug}`}>
              <Button className='buy_now' variant="outline-danger" size="md">
                Comandă acum
              </Button>
            </a>
          </div>
        </Col>
      )
    })

    return (
      <Fragment>
        <Container>
          <Row>
            {ProductView}
          </Row>
        </Container>
        <hr className='divider'/>
      </Fragment>
    )
  }
}

export default Product;
