import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Termeni from '../components/Termeni';

class Footer extends Component {
	render() {
		return (
			<Fragment>
            <footer id="copyright">
                <Container>
                    <Row>
                        <Col xs={12} md={6} lg={6}>
                        <ul className='footer_links'>
                            <Termeni />
                            <li>
                                <a title="Politica de confidentialitate" target="_blank" rel="noreferrer" href="https://elevatorauto.ro/politica-de-confidentialitate">Politica de confidentialitate</a>
                            </li>
                            <li>
                                <a title="Politica cookies" target="_blank" rel="noreferrer" href="https://elevatorauto.ro/cookies">Politica cookies</a>
                            </li>
                        </ul>
                    
                        </Col>
                        <Col xs={12} md={6} lg={6} className="development">
                        <p>
                            Created with ❤️ by 
                            <a title="Firma web design" target="_blank" rel="noreferrer" href="https://www.dualcv.net/">				
                            <picture>
                                <source alt="Firma web design" title="Firma web design" srcSet="https://www.dualcv.net/dualvalcost.webp" width="120" height="29" type="image/webp" />
                                <img alt="Firma web design" title="Firma web design" src="https://www.dualcv.net/dualvalcost.webp" width="120" height="29" />
                            </picture>
                            </a>
                        </p>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                        <ul className='legal_links'>
                            <li>
                                <a title="Soluționarea Alternativă a Litigiilor" href="https://anpc.ro/ce-este-sal/">
                                    <picture>
                                        <source srcSet="images/SAL.svg" alt="Soluționarea Alternativă a Litigiilor" title="Soluționarea Alternativă a Litigiilor" width="120px" height="29px" type="image/webp" />
                                        <img src="images/SAL.svg" alt="Soluționarea Alternativă a Litigiilor" title="Soluționarea Alternativă a Litigiilor" width="120px" height="29px" />
                                    </picture>
                                </a>
                            </li>
                            <li>
                                <a title="Soluționarea online a litigiilor" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO">
                                    <picture>
                                        <source srcSet="images/SOL.svg" alt="Soluționarea online a litigiilor" title="Soluționarea online a litigiilor" width="120px" height="29px" type="image/webp" />
                                        <img src="SOL.svg" alt="Soluționarea online a litigiilor" title="Soluționarea online a litigiilor" width="120px" height="29px" />
                                    </picture>
                                </a>
                            </li>
                        </ul>

                        </Col>
                    </Row>
                </Container>
            </footer>
			</Fragment>
		)
	}

}

export default Footer