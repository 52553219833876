import React, { Component, Fragment } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Animated} from "react-animated-css";

import RestClient from '../RestAPI/RestClient';
import AppUrl from '../RestAPI/AppUrl';

class Cta extends Component {

    constructor(){
        super();
        this.state={
            cta_title:"",
            cta_subtitle:"",
            cta_button:"",
            cta_url:"",
        }
    }
    
    componentDidMount(){
        RestClient.GetRequest(AppUrl.Cta).then(result=>{
            const CtaData = result.find(cta => cta.id === 4);
            if(CtaData) {
                this.setState({
                    cta_title: CtaData.cta_title,
                    cta_subtitle: CtaData.cta_subtitle,
                    cta_button: CtaData.cta_button,
                    cta_url: CtaData.cta_url,
                });
            }
        }) 
    }
    

	render() {
		return (
			<Fragment>
            <section className="cta">
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                        <Animated animationIn="bounceInUp" animationOut="fadeOut" animationInDelay="800" animationInDuration="1200" isVisible={true}>
                            <p><span>{this.state.cta_subtitle}</span></p>
                            <h5>{this.state.cta_title}</h5>
                            
                            {/* Verifică dacă cta_button și cta_url există înainte de a afișa butonul */}
                            {this.state.cta_button && this.state.cta_url &&
                                <a href={this.state.cta_url} target="_blank" rel="noopener noreferrer">
                                    <Button variant="outline-danger" size="lg">
                                    <i class="fa-solid fa-envelope-open-text"></i> {this.state.cta_button}
                                    </Button>
                                </a>
                            }
                            </Animated>
                        </Col>
                    </Row>
                </Container>
            </section>
			</Fragment>
		)
	}

}

export default Cta