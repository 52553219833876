import React, { Component, Fragment } from 'react'

// Import Swiper styles
import 'swiper/css';

import Hero from '../components/Hero'

import Cta from '../components/Cta';
import Contact from '../components/Contact';
import Title from '../components/Title';
import Product from '../components/Product.jsx';

export class Home extends Component {

  render() {
    return (
      <Fragment>

        <Hero />
        <Title />
        <Product />
        <Cta />
        <Contact />

      </Fragment>
    )
  }
}

export default Home